import { theme } from './ui/colors';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import ReactModal from 'react-modal';
import store, { history } from './store';
import App from './App';
import { saveState } from './api/localStorage';
import AppProvider from './containers/AppProvider';
import Callback from './containers/Callback';
import SnacksProvider from './containers/SnacksProvider';
import Auth from './containers/Auth';
import Login from './containers/Login';
import CheckYourEmails from './components/CheckYourEmails';
import { spacing } from './ui';
import GraphQLProvider from './containers/GraphQLProvider';
import { Toaster } from 'react-hot-toast';
import { MargaretProvider } from '@tymate/margaret';
import { pdfjs } from 'react-pdf';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'moment/locale/fr';
import './index.css';
import 'normalize.css';
import ErrorBoundary from './components/ErrorBoundary';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         retry: false,
         refetchOnWindowFocus: false,
      },
   },
});

ReactModal.defaultStyles = {
   overlay: {
      zIndex: 10,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
   },
   content: {
      position: 'absolute',
      top: '16px',
      left: '50%',
      transform: 'translateX(-50%)',
      border: 0,
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: 16,
      outline: 'none',
      padding: 0,
      width: spacing(40),
      maxWidth: `calc(100% - ${({ theme }) => theme.spacing(2)}`,
      boxShadow: `0 0 20px rgba(0,0,0,.15)`,
   },
};

moment.locale('fr');

store.subscribe(() => {
   const state = store.getState();
   const {
      ui: { selectedAgencyId, selectedAgencyUuid, selectedPlaceId, selectedPlaceUuid },
      user,
   } = state;

   saveState({
      user,
      ui: {
         selectedAgencyId,
         selectedAgencyUuid,
         selectedPlaceId,
         selectedPlaceUuid,
      },
   });

   window.userId = user.id;
});

render(
   <MargaretProvider theme={theme}>
      <ErrorBoundary>
         <Provider store={store}>
            <QueryClientProvider client={queryClient}>
               <SnacksProvider>
                  <ConnectedRouter history={history}>
                     <GraphQLProvider>
                        <AppProvider>
                           <Switch>
                              <Route path='/connexion' component={Login} />
                              <Route path='/inscription' component={Auth} />
                              <Route path='/mot-de-passe-oublie' component={Auth} />

                              <Route path='/callback/:type' component={Callback} />
                              <Route path='/email-envoye' component={CheckYourEmails} />

                              <Route component={App} />
                           </Switch>
                           <Toaster
                              position='bottom-center'
                              toastOptions={{
                                 style: {
                                    backgroundColor: '#323232',
                                    color: '#eef6f6',
                                 },
                              }}
                           />
                        </AppProvider>
                     </GraphQLProvider>
                  </ConnectedRouter>
               </SnacksProvider>
            </QueryClientProvider>
         </Provider>
      </ErrorBoundary>
   </MargaretProvider>,
   document.getElementById('root')
);

// registerServiceWorker();
