import { camelize, camelizeKeys } from 'humps';
import { normalize as rawNormalize } from 'normalizr';
import URLQueryBuilder from 'url-query-builder';
import { get, pickBy, round, snakeCase } from 'lodash';
import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import queryString from 'query-string';
import currencyFormatter from 'currency-formatter';
import { APP } from '../constants';

export const getParams = ({ search }) =>
   search
      .slice(1)
      .split('&')
      .map(chunk => {
         const keyValue = chunk.split('=');
         if (keyValue.length === 1) return chunk;

         return {
            [camelize(keyValue[0])]: keyValue[1],
         };
      })
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

export const normalize = (data, schema) => rawNormalize(camelizeKeys(data), schema);

export const getAttachmentUrl = url => {
   const [uri, search] = (url || '').split('?');
   const enrichedSearch = queryString.stringify({
      ...queryString.parse(search),
      access_token: get(JSON.parse(localStorage.getItem('state')), 'user.accessToken'),
   });

   return (url || '').indexOf(';base64') > -1 ? url : `${uri}?${enrichedSearch}`;
};

export const merge = (empirical = {}, payload = {}) => {
   let output = empirical;

   // eslint-disable-next-line
   for (const key in payload) {
      output = {
         ...output,
         [key]: {
            ...(output[key] || {}),
            ...(payload[key] || {}),
         },
      };
   }

   return output;
};

export const queryParamsToString = (params, stripPage = false) =>
   new URLQueryBuilder('', params)
      .delete(stripPage ? 'page' : '')
      .delete('per_page')
      .getUrl();

export const getBudgetPercentage = (allocated, spent) => {
   if (!allocated && !spent) {
      return '';
   }

   if (allocated === 0) {
      return '';
   }

   if (spent > allocated) {
      return `+${round((100 * (spent - allocated)) / allocated)} %`;
   }

   return `${round((100 * spent) / allocated)} %`;
};

export const formatDate = (date, frmt) => {
   try {
      return format(new Date(date), frmt, { locale: fr });
   } catch (err) {
      try {
         return format(date, frmt, { locale: fr });
      } catch (err) {
         return '';
      }
   }
};

export const pluralize = (input, count) =>
   input
      .split(' ')
      .map(i => `${i}${count > 1 ? 's' : ''}`)
      .join(' ');

export const overrideSearchWith = ({ location, ...rest }) =>
   queryString.stringify(
      pickBy({
         ...queryString.parse(location.search),
         ...rest,
      })
   );

export const getFileName = sourceFileName => {
   const [extension, ...chunks] = (sourceFileName || '').split('_').reverse();

   return chunks.reverse().join('_') + '.' + extension;
};

export const formatFiscalYear = fiscalYear => {
   if (!get(fiscalYear, 'displayName')) {
      return '';
   }

   return fiscalYear.displayName;
};

export const formatCurrency = amount =>
   currencyFormatter.format(amount / 100, {
      code: APP === 'VQC' ? 'CAD' : 'EUR',
   });

export const formatDuration = input => {
   if (!input) {
      return null;
   }

   let secondsInput = Math.floor(input);
   const days = Math.floor(secondsInput / 86400);
   secondsInput %= 86400;
   const hours = Math.floor(secondsInput / 3600);
   secondsInput %= 3600;
   const minutes = Math.floor(secondsInput / 60);

   let output = '';
   if (days > 0) {
      output += `${days} jour${days > 1 ? 's' : ''} `;
   }
   output += `${hours} h ${minutes} min`;

   return output;
};

export const getOrderVariableFromLocationSearch = ({ sort }) => {
   if (!Boolean(sort) || false) {
      return null;
   }

   return {
      direction: sort.charAt(0) === '-' ? 'desc' : 'asc',
      by: snakeCase(sort).replace('48_h', '48h'), // 😞
   };
};

export const getRoleName = role => {
   const roles = {
      lessor: 'Bailleur',
      coownership_board_member: 'Membre du conseil syndical',
      coowner_secondary: 'Copropriétaire invité',
      sergic_partner: 'Partenaire Sergic',
      sergic_partner_admin: 'Partenaire Sergic',
      tenant: 'Locataire',
      caretaker: 'Gardien',
      employee: 'Salarié immeuble',
      coowner: 'Copropriétaire',
      coowner_assistant: 'Assistant(e) de votre copropriété',
      coowner_accountant: 'Comptable de votre copropriété',
      director: 'Directeur d’agence / Conseiller achat-vente',
      recovery_responsible: 'Chargé(e) du recouvrement',
      claims_manager: 'Gestionnaire de sinistre',
      coowner_manager: 'Gestionnaire de votre immeuble',
      coowner_president: 'Président de copropriété',
      director_assistant: 'Directeur Adjoint',
      president_board_member: 'Président du conseil syndical',
      property_developer: 'Promoteur',
      viva_coowner_manager: 'Syndic référent',
      viva_coowner_assistant: 'Viva référent',
   };

   return roles[role];
};
export const getConversationStateWording = state => {
   switch (state) {
      case 'acknowledged':
         return 'Pris en compte';
      case 'resolved':
         return 'Résolu';
      case 'opened':
         return 'En attente';
      default:
         return state;
   }
};

export const formatIdToUuid = ({ model, id }) => btoa(`${model}-${id}`);

export const backURL = url =>
   url
      .split('/')
      .slice(0, -1)
      .join('/');

export const constructURI = (baseURL, params, formatKey = s => s) => {
   const queryString = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
      .map(key => formatKey(key) + '=' + params[key])
      .join('&');
   return queryString ? baseURL + '?' + queryString : baseURL;
};
