import { decamelizeKeys } from 'humps';
import { API_BASE, MOCK_API_BASE } from '../constants';
import store from 'store';
import { normalize } from 'utils';
import { sendSnack } from 'actions/snacks';
import { rawRefreshToken } from 'api/user';
import lodashGet from 'lodash/get';

const refreshFetch = (uri, options, hasTriedRefresh) => {
   return fetch(uri, options).then(async response => {
      if (response.status === 401) {
         const refreshTken = lodashGet(JSON.parse(localStorage.getItem('state')), 'user.refreshToken');

         if (!refreshTken || hasTriedRefresh) {
            store.dispatch({ type: 'LOGOUT' });
            return response;
         }

         try {
            const response = await rawRefreshToken(refreshTken);

            if (response.status === 401) {
               store.dispatch({ type: 'LOGOUT' });
               return;
            }
            const payload = await response.json();
            store.dispatch({ type: 'REFRESH_TOKEN_SUCCESS', payload });
         } catch (e) {
            store.dispatch({ type: 'LOGOUT' });
         }

         const opts = {
            ...options,
            headers: {
               ...options.headers,
               Authorization: `Bearer ${JSON.parse(localStorage.getItem('state')).user.accessToken}`,
            },
         };

         return refreshFetch(uri, opts, true).then(response => response);
      }

      return response;
   });
};

const getPaginationMeta = response => {
   const perPage = Number(response.headers.get('per-page'));
   const total = Number(response.headers.get('total'));
   const page = Number(response.headers.get('page'));
   const nextPage = Math.ceil(total / perPage) > page ? page + 1 : null;

   if (!perPage && !total && !page) {
      return null;
   }

   return {
      nextPage,
      page,
      perPage,
      total,
   };
};

const returnResponse = async response => {
   if (response.status === 401) {
      store.dispatch({ type: 'LOGOUT' });
      return response.json().then(error => Promise.reject(error));
   } else if (response.status === 403) {
      store.dispatch(
         sendSnack({
            type: 'error',
            duration: 3000,
            message: 'Vous n’êtes pas autorisé à visualiser cette page',
            action: 'OK',
         })
      );
      // history.replace('/');
      return Promise.reject();
   }

   if (response.status === 201 || response.status === 204) {
      return { json: {}, response };
   }

   const json = await response.json();
   return { json, response };
};

const handleResponse = ({ json, response, schema, params, withPagination = true }) => {
   if (!response.ok) {
      return Promise.reject(json ? json : response.statusText);
   }

   if (withPagination) {
      const pagination = {
         ...getPaginationMeta(response),
         params,
      };

      if (!schema) {
         return Promise.resolve({ ...json, pagination });
      }

      return Promise.resolve({
         ...normalize(json, schema),
         pagination,
      });
   }

   if (!schema) {
      return Promise.resolve(json);
   }

   return Promise.resolve({
      ...normalize(json, schema),
   });
};

const headers = input => {
   const state = JSON.parse(localStorage.getItem('state'));
   const hasToken = !!state && !!state.user && !!state.user.accessToken;
   const selectedPlaceId = state?.ui?.selectedPlaceId;

   if (hasToken) {
      const config = {
         ...input,
         headers: {
            ...input.headers,
            Authorization: `Bearer ${state.user.accessToken}`,
         },
      };
      if (Boolean(selectedPlaceId)) {
         config.headers['X-Current-Place-Id'] = selectedPlaceId;
      }
      return config;
   }

   return input;
};

const formatUri = (uri, { mock = false, ioni = false }) => {
   if (ioni) {
      return `${mock ? MOCK_API_BASE : API_BASE}${uri}`.replace(/\/v1\//, '/ioni/').replace(/\/v2\//, '/ioni/');
   }
   return `${mock ? MOCK_API_BASE : API_BASE}${uri}`;
};

export const get = (uri, { schema = undefined, params = {}, withPagination = true, mock = false, ioni = false } = {}) =>
   refreshFetch(
      formatUri(uri, { mock, ioni }),
      headers({
         method: 'GET',
      })
   )
      .then(returnResponse)
      .then(response => handleResponse({ ...response, schema, params, withPagination }));

export const rawGet = (uri, { mock = false, ioni = false } = {}) =>
   fetch(
      formatUri(uri, { mock, ioni }),
      headers({
         method: 'GET',
         header: {},
      })
   );

export const rawPost = (uri, payload = {}, { mock = false, ioni = false } = {}) =>
   fetch(
      formatUri(uri, { mock, ioni }),
      headers({
         method: 'POST',
         headers: {
            'Content-Type': 'application/json;charset=UTF-8',
         },
         body: JSON.stringify(decamelizeKeys(payload)),
      })
   );

export const post = (uri, payload = {}, { schema = undefined, mock = false, ioni = false } = {}) =>
   refreshFetch(
      formatUri(uri, { mock, ioni }),
      headers({
         method: 'POST',
         headers: {
            'Content-Type': 'application/json;charset=UTF-8',
         },
         body: JSON.stringify(decamelizeKeys(payload)),
      })
   )
      .then(returnResponse)
      .then(response => handleResponse({ ...response, schema }));

export const put = (uri, payload = {}, { schema = undefined, mock = false, ioni = false } = {}) =>
   refreshFetch(
      formatUri(uri, { mock, ioni }),
      headers({
         method: 'PUT',
         headers: {
            'Content-Type': 'application/json;charset=UTF-8',
         },
         body: JSON.stringify(decamelizeKeys(payload)),
      })
   )
      .then(returnResponse)
      .then(response => handleResponse({ ...response, schema }));

export const destroy = (uri, { mock = false, ioni = false } = {}) =>
   refreshFetch(
      formatUri(uri, { mock, ioni }),
      headers({
         method: 'delete',
         headers: {
            'Content-Type': 'application/json;charset=UTF-8',
         },
      })
   )
      .then(returnResponse)
      .then(handleResponse);
