import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { API_BASE, MOCK_API_BASE } from '../../constants';
import { pickBy } from 'lodash';

const api = axios.create({ baseURL: API_BASE });
const mockApi = axios.create({ baseURL: MOCK_API_BASE });

const formatConfig = (params, ...opts) => ({
   ...opts,
   params: decamelizeKeys(params),
});

const requestInterceptors = config => {
   const state = JSON.parse(localStorage.getItem('state'));

   const accessToken = state?.user?.impersonatedUser?.accessToken || state?.user?.accessToken;
   const selectedPlaceId = state?.ui?.selectedPlaceId;

   config.data = decamelizeKeys(config.data);
   config.headers['Accept'] = 'application/json';

   if (Boolean(accessToken)) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
   }

   if (Boolean(selectedPlaceId)) {
      config.headers['X-Current-Place-Id'] = selectedPlaceId;
   }

   return config;
};

api.interceptors.request.use(requestInterceptors);
mockApi.interceptors.request.use(requestInterceptors);

const formatHeaders = input => {
   const output = camelizeKeys(input);
   return pickBy({
      ...output,
      perPage: Boolean(output?.perPage) ? Number(output?.perPage) : null,
      page: Boolean(output?.page) ? Number(output?.page) : null,
      total: Boolean(output?.total) ? Number(output?.total) : null,
      lastPage: Boolean(output?.total) && Boolean(output?.perPage) ? Math.ceil(output?.total / output?.perPage) : null,
   });
};

const formatResponse = response => {
   if (!Boolean(response)) {
      return response;
   }

   return camelizeKeys({
      ...response,
      headers: formatHeaders(response?.headers),
   });
};

const formatUri = (uri, { ioni }) => {
   if (ioni) {
      return uri.replace(/\/v1\//, '/ioni/').replace(/\/v2\//, '/ioni/');
   }
   return uri;
};

export const get = (uri, { params = {}, options = {}, mock = false, ioni = false } = {}) =>
   mock
      ? mockApi.get(formatUri(uri, { ioni }), formatConfig(params, options)).then(formatResponse)
      : api.get(formatUri(uri, { ioni }), formatConfig(params, options)).then(formatResponse);

export const post = (uri, payload = {}, { mock = false, ioni = false } = {}) =>
   mock
      ? mockApi.post(formatUri(uri, { ioni }), payload).then(formatResponse)
      : api.post(formatUri(uri, { ioni }), payload).then(formatResponse);

export const put = (uri, payload = {}, { mock = false, ioni = false } = {}) =>
   mock
      ? mockApi.put(formatUri(uri, { ioni }), payload).then(formatResponse)
      : api.put(formatUri(uri, { ioni }), payload).then(formatResponse);

export const destroy = (uri, { mock = false, ioni = false } = {}) =>
   mock
      ? mockApi.delete(formatUri(uri, { ioni })).then(formatResponse)
      : api.delete(formatUri(uri, { ioni })).then(formatResponse);
