import { destroy, get, post, put, rawGet } from './';
import { get as getV2, post as postV2 } from './v2/index';
import {
  budget,
  conversation,
  coownershipDocument,
  coownershipFolder,
  equipment,
  fiscalYear,
  maintenanceContract,
  maintenanceContractsCategory,
  notification,
  place,
  report,
  reportCategory,
  treasury,
  user,
} from './schema';

export const getPlaces = payload => get('/v1/users/me/places', { schema: [place] });
export const getPlace = id => get(`/v1/places/${id}`, { schema: place });
export const getEquipment = id => get(`/v1/equipments/${id}`, { schema: equipment });

export const getPlaceEquipments = id =>
  get(`/v1/places/${id}/equipments`, { schema: [equipment] });
export const getPlaceContacts = id => get(`/v1/places/${id}/contacts`, { schema: [user] });
export const getPlaceContactsPaginated = id =>
  get(`/v1/places/${id}/contacts?page=1`, { schema: [user] });
export const getPlaceContactsCSV = id =>
  rawGet(`/v1/identities?by_place=${id}&to_csv=true`);
export const getPlaceBuildings = ({ queryKey }) =>
  get(`/v1/places/${queryKey[1]}/buildings`, { schema: undefined, params: {}, withPagination: false });

export const getPlaceConversations = id =>
  get(`/v1/places/${id}/conversations`, { schema: [conversation] });
export const getPlaceReports = id => get(`/v1/places/${id}/reports`, { schema: [report] });
export const getPlaceTreasuries = id =>
  get(`/v1/places/${id}/treasuries`, { schema: [treasury] });
export const getPlaceBudgets = (placeId, fiscalYearId, ioni) =>
  get(`/v2/budgets?place_id=${placeId}${fiscalYearId ? `&fiscal_year_id=${fiscalYearId}` : ''}`, { schema: [budget], ioni });
export const getPlaceMaintenanceWorks = id =>
  get(`/v1/places/${id}/maintenance_works`);

export const getPlaceCoownershipFolders = ({ sliceId, params }) =>
  get(`/v2/places/${sliceId}/coownership_folders${params}`, { schema: [coownershipFolder] });

export const getCoownershipFolderDocuments = ({ sliceId, params }) =>
  get(`/v2/places/${sliceId}/coownership_documents${params}`, { schema: [coownershipDocument] });

export const createCoownershipFolder = ({ placeId, payload }) =>
  post(`/v1/places/${placeId}/coownership_folders`, payload, { schema: coownershipFolder });

export const updateCoownershipFolder = ({
  placeId,
  payload,
  coownershipFolderId,
}) =>
  put(
    `/v1/places/${placeId}/coownership_folders/${coownershipFolderId}`,
    payload,
     { schema: coownershipFolder },
  );

export const deleteCoownershipFolder = ({ placeId, coownershipFolderId }) =>
  destroy(
    `/v1/places/${placeId}/coownership_folders/${coownershipFolderId}`,
     { schema: coownershipFolder },
  );

export const postPlaceConversation = (id, payload) =>
  post(`/v1/places/${id}/conversations`, payload, { schema: conversation });

export const getPlaceNotifications = id =>
  get(`/v1/places/${id}/place_actualities`, { schema: [notification] });
export const postPlaceActuality = (id, payload) =>
  post(`/v1/places/${id}/place_actualities`, payload, { schema: notification });
export const getNotification = id =>
  get(`/v1/place_actualities/${id}`, { schema: notification });
export const putPlaceActuality = (notificationId, payload) =>
  put(`/v1/place_actualities/${notificationId}`, payload, { schema: notification });
export const deleteNotification = id => destroy(`/v1/place_actualities/${id}`);

export const getPlaceCollaborators = id =>
  get(`/v1/places/${id}/contacts?sergic_partner=true`, { schema: [user] });

export const getPlaceFiscalYears = (id, ioni) =>
  get(`/v2/places/${id}/fiscal_years`, { schema: [fiscalYear], ioni });

export const getPlaceMaintenanceContractsCategories = id =>
  get(`/v1/places/${id}/maintenance_contract_categories`,  { schema: [maintenanceContractsCategory] });

export const getPlaceMaintenanceContract = id =>
  get(`/v1/maintenance_contracts/${id}`, { schema: maintenanceContract });

export const getReportCategories = () =>
  get(`/v1/report_categories`, { schema: [reportCategory] });

export const getShareableRoles = () => get(`/v1/roles?filter=for_invitation`);
export const getPlaceInvitations = placeId =>
  get(`/v1/places/${placeId}/invitations`);

export const createTmpPlace = payload =>
  post(`/v1/temporary_places`, payload, { schema: place });
export const updateTmpPlace = (id, payload) =>
  put(`/v1/temporary_places/${id}`, payload, { schema: place });
export const generateTmpPlaceInvitationLink = id =>
  put(`/v1/temporary_places/${id}/invitation_link`, {}, { schema: place });
export const cleanTmpPlace = id =>
  post(`/v1/temporary_places/${id}/clean`, {}, { schema: place });
export const syncTmpPlace = id =>
  post(`/v1/temporary_places/${id}/synchronize`, {}, { schema: place });
export const getPlaceDistributionLists = id =>
  get(`/v1/places/${id}/distribution_lists`);
export const searchPlaceContacts = (id, query) =>
  get(`/v1/places/${id}/contacts?${query}`, { schema: [user] });

export const resyncPlace = id =>
  post(`/v1/places/require_sync/${id}`, {}, { schema: place });

export const getPlaceCoowners = placeId =>
  getV2(
    `/v1/places/${placeId}/coowners?coownership_board_members=true`,
     {
       params: undefined,
       options: undefined,
       mock: undefined,
     });

export const postExpensesManager = (placeId, payload) =>
  postV2(`/v2/places/${placeId}/expenses/expenses_manager`, payload);
