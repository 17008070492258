import styled from 'styled-components';
import { ButtonReset, Media, MediaImage, MediaBody } from 'ui';
import { SidebarWrapper, SidebarMenu } from 'ui/sidebar';
import { useAppState } from 'hooks';
import { motion } from 'framer-motion';
import Avatar from 'components/Avatar';
import { get } from 'lodash';
import {
  PlaceTriggerName,
  PlaceTriggerLabel,
  AgencySwitcher,
} from 'components/AgencySwitcher';
import { capitalize } from '@tymate/sergic';

const Backdrop = styled(ButtonReset)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: none;
`;

const SidebarHeader = styled.div`
  padding-left: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()};
`;

const sidebarVariants = {
  open: {
    transform: 'translateX(-280px)',
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
  closed: {
    transform: 'translateX(0px)',
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
};

const backdropVariants = {
  open: {
    display: 'block',
    opacity: 1,
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
  closed: {
    display: 'none',
    opacity: 0,
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
};

const PlaceSidebar = ({ place, agency }) => {
  const { mobilePlaceIsShown, isMobile, closeMobilePlace } = useAppState();

  return (
    <>
      <Backdrop
        as={isMobile ? motion.button : null}
        animate={mobilePlaceIsShown ? 'open' : 'closed'}
        variants={backdropVariants}
        onClick={closeMobilePlace}
      />

      <SidebarWrapper
        as={isMobile ? motion.aside : null}
        animate={mobilePlaceIsShown ? 'open' : 'closed'}
        variants={sidebarVariants}
        side="right"
      >
        <SidebarHeader>
          <Media>
            <MediaImage>
              <Avatar
                size="small"
                avatarUrl={
                  get(place, 'pictureUrl') || get(agency, 'pictureUrl')
                }
              />
            </MediaImage>
            <MediaBody>
              <PlaceTriggerName>
                {capitalize(get(place, 'displayName', 'Tous les lieux'))}
              </PlaceTriggerName>
              <PlaceTriggerLabel>
                {capitalize(get(agency, 'displayName', 'Portefeuille'))}
              </PlaceTriggerLabel>
            </MediaBody>
          </Media>
        </SidebarHeader>
        <SidebarMenu>
          <AgencySwitcher />
        </SidebarMenu>
      </SidebarWrapper>
    </>
  );
};

export default PlaceSidebar;
