import styled from 'styled-components';
import Breadcrumb from 'components/Breadcrumb';
import { media, TopBarWrapper } from 'ui';
import { Button } from '@tymate/sergic';
import { MdSettings } from 'react-icons/md';
import ExpensesRightsModal from './ExpensesRightsModal';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useRoles from '../hooks/roles';
import { getSelectedPlace } from 'client/src/selectors';

const BreadcrumbWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: ${({ theme }) => theme.primary};
   padding: ${({ theme }) => theme.spacing()};
   color: #fff;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 2;
   min-height: 60px;

   ${media.desktop`
    min-height: 0;
    position: static;
    background-color: #fff;
    padding: 0;
    color: inherit;
    justify-content: flex-start;
  `};
`;

export const TopBarButton = styled(Button)`
   font-size: ${({ theme }) => theme.size(2)};
   color: ${({ theme }) => theme.primary} !important;
   padding: ${({ theme }) => theme.spacing(0.65)};
   cursor: pointer;
   background-color: #fff;

   svg {
      display: flex;
      margin: 0;
   }

   &:disabled {
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.textLight};
      opacity: 0.8;
   }
`;

const TopBar = ({ customTranslations, children, showManageExpenses }) => {
   const { isAllowedTo } = useRoles();
   const [settingsModalIsShown, setSettingsModalIsShown] = useState(false);
   const { selectedPlaceId, place } = useSelector(state => ({
      selectedPlaceId: state.ui.selectedPlaceId,
      place: getSelectedPlace(state),
   }));

   return (
      <TopBarWrapper full>
         <BreadcrumbWrapper>
            <Breadcrumb customTranslations={customTranslations} />
         </BreadcrumbWrapper>

         {children}

         {showManageExpenses && selectedPlaceId && place.canManageExpenses && isAllowedTo('updateExpensesManager') && (
            <>
               <TopBarButton onClick={() => setSettingsModalIsShown(true)}>
                  <MdSettings />
               </TopBarButton>
               <ExpensesRightsModal
                  isOpen={settingsModalIsShown}
                  onCancelClick={() => setSettingsModalIsShown(false)}
                  afterSubmit={() => setSettingsModalIsShown(false)}
               />
            </>
         )}
      </TopBarWrapper>
   );
};

export default TopBar;
