import styled from 'styled-components';
import DataTable from 'components/DataTable';
import queryString from 'query-string';
import EmptyState from 'components/EmptyState';
import Tooltip from 'components/Tooltip';
import { ListItemTitle, ListItemMeta, ListItemDescription } from 'ui/lists';
import eseisBadge from 'images/icons/badge-eseis.svg';
import vivaBadge from 'images/icons/badge-viva.svg';
import syndicOneBadge from 'images/icons/badge-syndic-one.svg';
import neufBadge from 'images/icons/badge-neuf.svg';
import { MdAccessTime, MdStar } from 'react-icons/md';
import { color, ui } from 'ui/colors';
import { Container, TopBarButtonWrapper } from 'ui';
import TopBar from 'components/TopBar';
import { Pills, Pill, capitalize } from '@tymate/sergic';
import { get, truncate, pickBy } from 'lodash';
import { getOrderVariableFromLocationSearch } from 'utils';
import { useAppState } from 'hooks';
import { APP } from '../../constants';
import { Link } from 'react-router-dom';
import { Buttons } from '@tymate/margaret';
import { Button, PillsNavBar } from '@tymate/sergic';
import { gql } from 'apollo-boost';
import { useSelector } from 'react-redux';
import { getSelectedPlace, getSelectedAgency } from 'selectors/agencies';
import { useQuery } from '@apollo/react-hooks';

const GET_PLACES = gql`
  query allPlaces(
    $search: String
    $after: String
    $before: String
    $inPortfolio: Boolean
    $sergicOffer: PlaceSergicOffers
    $agencyId: ID
  ) {
    allPlaces(
      search: $search
      first: 20
      after: $after
      before: $before
      inPortfolio: $inPortfolio
      filter: { sergicOffer: { eq: $sergicOffer }, agencyId: { eq: $agencyId } }
    ) {
      pageInfo {
        endCursor
        hasNextPage
        startCursor
        hasPreviousPage
      }
      edges {
        node {
          id
          sqlId
          displayName
          sergicOffer
          temporary
          inPortfolio
        }
      }
    }
  }
`;

const Wrapper = styled.div``;

const Badge = styled.img`
  align-self: flex-start;
  display: inline-block;
  vertical-align: middle;
`;

export const getOfferBadge = offer => {
  switch (offer) {
    case 'ESE':
      return <Badge src={eseisBadge} alt="éseis" />;
    case 'VSY':
      return <Badge src={vivaBadge} alt="Viva Syndic" />;
    case 'DIR':
    case 'SO':
      return <Badge src={syndicOneBadge} alt="Syndic One" />;
    case 'NEUF':
      return <Badge src={neufBadge} alt="Neuf" />;
    default:
      return null;
  }
};

const PlacesList = ({ match, location, customTranslations }) => {
  const { isMobile } = useAppState();
  const selectedAgencyId = useSelector(state => state.ui.selectedAgencyId);
  const selectedAgencyUuid = useSelector(getSelectedAgency)?.uuid;
  const selectedPlaceUuid = useSelector(getSelectedPlace)?.uuid;

  const query = queryString.parse(location.search);

  const variables = pickBy({
    order: getOrderVariableFromLocationSearch(query),
    coownerOnly: query?.coownerOnly === 'true',
    sergicOnly: query?.sergicOnly === 'true',
    agencyId: selectedAgencyUuid,
    byPlace: selectedPlaceUuid,
    inPortfolio: !Boolean(selectedAgencyId),
    search: query?.search,
    sergicOffer: query?.sergicOffer,
  });

  const { data: placesData, loading, fetchMore } = useQuery(GET_PLACES, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const headings = [
    {
      slug: 'displayName',
      label: 'Nom',
      type: {
        name: 'text',
        lines: [
          { height: 21, width: 250 },
          { height: 18, width: 300, marginTop: 8 },
          { height: 18, width: 400, marginTop: 8 },
        ],
      },
      nowrap: true,
      full: true,
      fixed: true,
    },
    {
      slug: 'offer',
      label: 'Offre',
      type: {
        name: 'status',
      },
      nowrap: true,
      fixed: true,
      width: '12%',
      textAlign: 'center',
    },
    {
      slug: 'inPortfolio',
      label: 'Portefeuille',
      type: {
        name: 'status',
      },
      nowrap: true,
      fixed: true,
      width: '12%',
      textAlign: 'center',
    },
    {
      slug: 'temporary',
      label: 'Temporaire',
      type: {
        name: 'status',
      },
      nowrap: true,
      fixed: true,
      width: '12%',
      textAlign: 'center',
    },
  ].filter(({ slug }) =>
    isMobile ? ['inPortfolio', 'temporary'].indexOf(slug) === -1 : true,
  );

  const handleLoadNextPage = () => {
    fetchMore({
      variables: {
        after: placesData?.allPlaces?.pageInfo?.endCursor,
        first: 20,
      },
      updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
    });
  };

  const handleLoadPreviousPage = () => {
    fetchMore({
      variables: {
        before: placesData?.allPlaces?.pageInfo?.startCursor,
        last: 20,
      },
      updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
    });
  };

  const data = get(placesData, 'allPlaces.edges', [])
    .map(({ node }) => ({ ...node }))
    .map(place => ({
      displayName: {
        value: place.displayName,
        render: () => (
          <div>
            <ListItemTitle>
              {isMobile && (
                <>
                  {place.inPortfolio && (
                    <Tooltip position="right" tip="Dans mon portefeuille">
                      <MdStar size={16} color={color('yellow')} />
                    </Tooltip>
                  )}

                  {place.temporary && (
                    <Tooltip left tip="Lieu temporaire">
                      <MdAccessTime size={16} color={ui('textLight')} />
                    </Tooltip>
                  )}

                  {(place.temporary || place.inPortfolio) && ' '}
                </>
              )}
              {capitalize(place.displayName)}
            </ListItemTitle>
            <ListItemMeta>{capitalize(place.displayAgency)}</ListItemMeta>
            <ListItemDescription>
              {truncate(get(place, 'description'), { length: 128 })}
            </ListItemDescription>
          </div>
        ),
      },
      offer: {
        value: place.sergicOffer,
        render: () => (
          <Tooltip tip={place.sergicOffer}>
            {getOfferBadge(place.sergicOffer)}
          </Tooltip>
        ),
      },
      inPortfolio: {
        value: place.inPortfolio ? 1 : 0,
        render: () =>
          place.inPortfolio ? (
            <Tooltip tip="Dans mon portefeuille">
              <MdStar color={color('yellow')} />
            </Tooltip>
          ) : null,
      },
      temporary: {
        value: place.temporary ? 1 : 0,
        render: () =>
          place.temporary ? (
            <Tooltip left tip="Lieu temporaire">
              <MdAccessTime color={ui('textLight')} />
            </Tooltip>
          ) : null,
      },
      cls: place.unreadMessageCount > 0 ? 'bold' : '',
      path: `/lieux/${place.sqlId}`,
      id: place.id,
    }));

  return (
    <Wrapper>
      <TopBar customTranslations={customTranslations}>
        {APP !== 'VQC' && (
          <PillsNavBar>
            <Pills>
              <Pill
                as={Link}
                to={match.url}
                exact
                isActive={!query.sergicOffer}
              >
                Tous
              </Pill>

              <Pill
                as={Link}
                isActive={query.sergicOffer === 'ESE'}
                to={{ pathname: match.url, search: '?sergicOffer=ESE' }}
              >
                éseis
              </Pill>

              <Pill
                as={Link}
                isActive={query.sergicOffer === 'VSY'}
                to={{ pathname: match.url, search: '?sergicOffer=VSY' }}
              >
                Viva Syndic
              </Pill>

              <Pill
                as={Link}
                isActive={query.sergicOffer === 'ECO'}
                to={{ pathname: match.url, search: '?sergicOffer=ECO' }}
              >
                e-Copro
              </Pill>

              <Pill
                as={Link}
                isActive={query.sergicOffer === 'NEUF'}
                to={{ pathname: match.url, search: '?sergicOffer=NEUF' }}
              >
                Neuf
              </Pill>
            </Pills>
          </PillsNavBar>
        )}

        <TopBarButtonWrapper>
          <Button to={`${match.url}/nouveau`}>Welcome process</Button>
        </TopBarButtonWrapper>
      </TopBar>

      <Container>
        <DataTable
          headings={headings}
          data={data}
          loading={loading}
          emptyState={
            <EmptyState icon="messages">
              Aucun message ne correspond aux critères
            </EmptyState>
          }
        />

        {(placesData?.allPlaces?.pageInfo?.hasPreviousPage ||
          placesData?.allPlaces?.pageInfo?.hasNextPage) && (
          <Buttons hasTopMargin>
            <Button
              variant="pagination"
              onClick={handleLoadPreviousPage}
              disabled={!placesData?.allPlaces?.pageInfo?.hasPreviousPage}
            >
              Page précédente
            </Button>

            <Button
              variant="pagination"
              onClick={handleLoadNextPage}
              disabled={!placesData?.allPlaces?.pageInfo?.hasNextPage}
            >
              Page suivante
            </Button>
          </Buttons>
        )}
      </Container>
    </Wrapper>
  );
};

export default PlacesList;
