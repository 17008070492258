import { createAction } from 'redux-actions';
import * as Api from 'api/places';
import { history } from 'store';
import { createActionWithNestedPagination } from 'utils/pagination';

const requestPlaces = createAction('PLACES_REQUEST');
const receivePlaces = createAction('PLACES_SUCCESS');
const failPlaces = createAction('PLACES_ERROR');

export const getPlaces = () => dispatch => {
  dispatch(requestPlaces());

  return Api.getPlaces().then(
    response => dispatch(receivePlaces(response)),
    error => dispatch(failPlaces(error)),
  );
};

const requestPlaceEquipments = createAction('PLACE_EQUIPMENTS_REQUEST');
const receivePlaceEquipments = createAction('PLACE_EQUIPMENTS_SUCCESS');
const failPlaceEquipments = createAction('PLACE_EQUIPMENTS_ERROR');

export const getPlaceEquipments = id => dispatch => {
  dispatch(requestPlaceEquipments(id));

  return Api.getPlaceEquipments(id).then(
    data =>
      dispatch(
        receivePlaceEquipments({
          id,
          data,
        }),
      ),
    error => dispatch(failPlaceEquipments({ id, error })),
  );
};

const requestPlace = createAction('PLACE_REQUEST');
const receivePlace = createAction('PLACE_SUCCESS');
const failPlace = createAction('PLACE_ERROR');

export const getPlace = id => dispatch => {
  dispatch(requestPlace(id));

  return Api.getPlace(id).then(
    response => dispatch(receivePlace(response)),
    error => dispatch(failPlace(error)),
  );
};

const requestPlaceBudgets = createAction('PLACE_BUDGETS_REQUEST');
const receivePlaceBudgets = createAction('PLACE_BUDGETS_SUCCESS');
const failPlaceBudgets = createAction('PLACE_BUDGETS_ERROR');

export const getPlaceBudgets = (placeId, fiscalYearId, ioni) => dispatch => {
  dispatch(requestPlaceBudgets(placeId));

  return Api.getPlaceBudgets(placeId, fiscalYearId, ioni).then(
    data => dispatch(receivePlaceBudgets({ placeId, fiscalYearId, data })),
    error => dispatch(failPlaceBudgets(error)),
  );
};

const requestPlaceTreasuries = createAction('PLACE_TREASURIES_REQUEST');
const receivePlaceTreasuries = createAction('PLACE_TREASURIES_SUCCESS');
const failPlaceTreasuries = createAction('PLACE_TREASURIES_ERROR');

export const getPlaceTreasuries = placeId => dispatch => {
  dispatch(requestPlaceTreasuries(placeId));

  return Api.getPlaceTreasuries(placeId).then(
    data => dispatch(receivePlaceTreasuries({ placeId, data })),
    error => dispatch(failPlaceTreasuries(error)),
  );
};

const requestPlaceMaintenanceWorks = createAction(
  'PLACE_MAINTENANCE_WORKS_REQUEST',
);
const receivePlaceMaintenanceWorks = createAction(
  'PLACE_MAINTENANCE_WORKS_SUCCESS',
);
const failPlaceMaintenanceWorks = createAction('PLACE_MAINTENANCE_WORKS_ERROR');

export const getPlaceMaintenanceWorks = placeId => dispatch => {
  dispatch(requestPlaceMaintenanceWorks(placeId));

  return Api.getPlaceMaintenanceWorks(placeId).then(
    data => dispatch(receivePlaceMaintenanceWorks({ placeId, data })),
    error => dispatch(failPlaceMaintenanceWorks(error)),
  );
};

const requestPlaceCoownershipFolders = createAction(
  'PLACE_COOWNERSHIP_FOLDERS_REQUEST',
);
const receivePlaceCoownershipFolders = createActionWithNestedPagination(
  'PLACE_COOWNERSHIP_FOLDERS_SUCCESS',
);
const failPlaceCoownershipFolders = createAction(
  'PLACE_COOWNERSHIP_FOLDERS_ERROR',
);

export const getPlaceCoownershipFolders = ({ params, sliceId }) => dispatch => {
  dispatch(requestPlaceCoownershipFolders({ params, sliceId }));

  return Api.getPlaceCoownershipFolders({ params, sliceId }).then(
    data => dispatch(receivePlaceCoownershipFolders({ params, sliceId, data })),
    error => dispatch(failPlaceCoownershipFolders({ params, sliceId, error })),
  );
};

const requestEquipment = createAction('EQUIPMENT_REQUEST');
const receiveEquipment = createAction('EQUIPMENT_SUCCESS');
const failEquipment = createAction('EQUIPMENT_ERROR');

export const getEquipment = (id, loadPlace = false) => dispatch => {
  dispatch(requestEquipment(id));

  return Api.getEquipment(id).then(
    response => dispatch(receiveEquipment(response)),
    error => dispatch(failEquipment(error)),
  );
};

const requestpostPlaceActuality = createAction('NEW_NOTIFICATIONS_REQUEST');
const receivepostPlaceActuality = createAction('NEW_NOTIFICATIONS_SUCCESS');
const failpostPlaceActuality = createAction('NEW_NOTIFICATIONS_ERROR');

export const postPlaceActuality = (placeId, payload) => dispatch => {
  dispatch(requestpostPlaceActuality());

  return Api.postPlaceActuality(placeId, payload).then(
    data => {
      dispatch(receivepostPlaceActuality({ placeId, data }));
    },
    error => {
      dispatch(failpostPlaceActuality(error));
      throw new Error(error);
    },
  );
};

const requestputPlaceActuality = createAction('EDIT_NOTIFICATION_REQUEST');
const receiveputPlaceActuality = createAction('EDIT_NOTIFICATION_SUCCESS');
const failputPlaceActuality = createAction('EDIT_NOTIFICATION_ERROR');

export const putPlaceActuality = (
  placeId,
  notificationId,
  payload,
) => dispatch => {
  dispatch(requestputPlaceActuality());

  return Api.putPlaceActuality(placeId, notificationId, payload).then(
    data => dispatch(receiveputPlaceActuality({ notificationId, data })),
    error => {
      dispatch(failputPlaceActuality(error));
      throw new Error(error);
    },
  );
};

const requestPlaceFiscalYears = createAction('PLACE_FISCAL_YEARS_REQUEST');
const receivePlaceFiscalYears = createAction('PLACE_FISCAL_YEARS_SUCCESS');
const failPlaceFiscalYears = createAction('PLACE_FISCAL_YEARS_ERROR');

export const getPlaceFiscalYears = (placeId, ioni) => dispatch => {
  dispatch(requestPlaceFiscalYears(placeId));

  return Api.getPlaceFiscalYears(placeId, ioni).then(
    data => dispatch(receivePlaceFiscalYears({ placeId, data })),
    error => dispatch(failPlaceFiscalYears(error)),
  );
};

const requestPlaceMaintenanceContractsCategories = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORIES_REQUEST',
);
const receivePlaceMaintenanceContractsCategories = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORIES_SUCCESS',
);
const failPlaceMaintenanceContractsCategories = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORIES_ERROR',
);

export const getPlaceMaintenanceContractsCategories = placeId => dispatch => {
  dispatch(requestPlaceMaintenanceContractsCategories(placeId));

  return Api.getPlaceMaintenanceContractsCategories(placeId).then(
    data =>
      dispatch(receivePlaceMaintenanceContractsCategories({ placeId, data })),
    error => dispatch(failPlaceMaintenanceContractsCategories(error)),
  );
};

const requestPlaceMaintenanceContract = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORY_REQUEST',
);
const receivePlaceMaintenanceContract = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORY_SUCCESS',
);
const failPlaceMaintenanceContract = createAction(
  'PLACE_MAINTENANCE_CONTRACTS_CATEGORY_ERROR',
);

export const getPlaceMaintenanceContract = (
  placeId,
  contractId,
) => dispatch => {
  dispatch(requestPlaceMaintenanceContract(contractId));

  return Api.getPlaceMaintenanceContract(contractId).then(
    data => dispatch(receivePlaceMaintenanceContract({ contractId, data })),
    error => dispatch(failPlaceMaintenanceContract(error)),
  );
};

const requestCreateTmpPlace = createAction('CREATE_TMP_PLACE_REQUEST');
const receiveCreateTmpPlace = createAction('CREATE_TMP_PLACE_SUCCESS');
const failCreateTmpPlace = createAction('CREATE_TMP_PLACE_ERROR');

export const createTmpPlace = payload => dispatch => {
  dispatch(requestCreateTmpPlace());

  return Api.createTmpPlace(payload).then(
    data => {
      history.push(`/lieux/${data.result}`);
      dispatch(receiveCreateTmpPlace(data));
    },
    error => dispatch(failCreateTmpPlace(error)),
  );
};

const requestUpdateTmpPlace = createAction('UPDATE_TMP_PLACE_REQUEST');
const receiveUpdateTmpPlace = createAction('UPDATE_TMP_PLACE_SUCCESS');
const failUpdateTmpPlace = createAction('UPDATE_TMP_PLACE_ERROR');

export const updateTmpPlace = (id, payload) => dispatch => {
  dispatch(requestUpdateTmpPlace());

  return Api.updateTmpPlace(id, payload).then(
    data => dispatch(receiveUpdateTmpPlace(data)),
    error => dispatch(failUpdateTmpPlace(error)),
  );
};

const requestGenerateTmpPlaceInvitationLink = createAction(
  'GENERATE_TMP_PLACE_INVITATION_LINK_REQUEST',
);
const receiveGenerateTmpPlaceInvitationLink = createAction(
  'GENERATE_TMP_PLACE_INVITATION_LINK_SUCCESS',
);
const failGenerateTmpPlaceInvitationLink = createAction(
  'GENERATE_TMP_PLACE_INVITATION_LINK_ERROR',
);

export const generateTmpPlaceInvitationLink = id => dispatch => {
  dispatch(requestGenerateTmpPlaceInvitationLink());

  return Api.generateTmpPlaceInvitationLink(id).then(
    data => dispatch(receiveGenerateTmpPlaceInvitationLink(data)),
    error => dispatch(failGenerateTmpPlaceInvitationLink(error)),
  );
};

const requestCleanTmpPlace = createAction('CLEAN_TMP_PLACE_REQUEST');
const receiveCleanTmpPlace = createAction('CLEAN_TMP_PLACE_SUCCESS');
const failCleanTmpPlace = createAction('CLEAN_TMP_PLACE_ERROR');

export const cleanTmpPlace = id => dispatch => {
  dispatch(requestCleanTmpPlace());

  return Api.cleanTmpPlace(id).then(
    data => dispatch(receiveCleanTmpPlace(data)),
    error => dispatch(failCleanTmpPlace(error)),
  );
};

const requestSyncTmpPlace = createAction('SYNC_TMP_PLACE_REQUEST');
const receiveSyncTmpPlace = createAction('SYNC_TMP_PLACE_SUCCESS');
const failSyncTmpPlace = createAction('SYNC_TMP_PLACE_ERROR');

export const syncTmpPlace = id => dispatch => {
  dispatch(requestSyncTmpPlace());

  return Api.syncTmpPlace(id).then(
    data => dispatch(receiveSyncTmpPlace(data)),
    error => dispatch(failSyncTmpPlace(error)),
  );
};

const requestResyncPlace = createAction('SYNC_TMP_PLACE_REQUEST');
const receiveResyncPlace = createAction('SYNC_TMP_PLACE_SUCCESS');
const failResyncPlace = createAction('SYNC_TMP_PLACE_ERROR');

export const resyncPlace = id => dispatch => {
  dispatch(requestResyncPlace());

  return Api.resyncPlace(id).then(
    data => dispatch(receiveResyncPlace(data)),
    error => dispatch(failResyncPlace(error)),
  );
};

const requestCreateCoownershipFolder = createAction(
  'CREATE_COOWNERSHIP_FOLDER_REQUEST',
);
const receiveCreateCoownershipFolder = createActionWithNestedPagination(
  'CREATE_COOWNERSHIP_FOLDER_SUCCESS',
);
const failCreateCoownershipFolder = createAction(
  'CREATE_COOWNERSHIP_FOLDER_ERROR',
);

export const createCoownershipFolder = ({ placeId, payload }) => dispatch => {
  dispatch(requestCreateCoownershipFolder(placeId));

  return Api.createCoownershipFolder({ placeId, payload }).then(
    data => dispatch(receiveCreateCoownershipFolder({ placeId, data })),
    error => dispatch(failCreateCoownershipFolder({ placeId, error })),
  );
};

const requestUpdateCoownershipFolder = createAction(
  'UPDATE_COOWNERSHIP_FOLDER_REQUEST',
);
const receiveUpdateCoownershipFolder = createActionWithNestedPagination(
  'UPDATE_COOWNERSHIP_FOLDER_SUCCESS',
);
const failUpdateCoownershipFolder = createAction(
  'UPDATE_COOWNERSHIP_FOLDER_ERROR',
);

export const updateCoownershipFolder = ({
  placeId,
  coownershipFolderId,
  payload,
}) => dispatch => {
  dispatch(requestUpdateCoownershipFolder({ placeId, ...payload }));

  return Api.updateCoownershipFolder({
    placeId,
    coownershipFolderId,
    payload,
  }).then(
    data =>
      dispatch(
        receiveUpdateCoownershipFolder({ placeId, coownershipFolderId, data }),
      ),
    error => dispatch(failUpdateCoownershipFolder({ placeId, error })),
  );
};

const requestCoownershipFolderDocuments = createAction(
  'COOWNERSHIP_FOLDER_DOCUMENTS_REQUEST',
);
const receiveCoownershipFolderDocuments = createActionWithNestedPagination(
  'COOWNERSHIP_FOLDER_DOCUMENTS_SUCCESS',
);
const failCoownershipFolderDocuments = createAction(
  'COOWNERSHIP_FOLDER_DOCUMENTS_ERROR',
);

export const getCoownershipFolderDocuments = ({
  sliceId,
  params,
}) => dispatch => {
  dispatch(requestCoownershipFolderDocuments({ sliceId, params }));

  return Api.getCoownershipFolderDocuments({
    sliceId,
    params,
  }).then(
    data =>
      dispatch(
        receiveCoownershipFolderDocuments({
          params,
          sliceId,
          data,
        }),
      ),
    error =>
      dispatch(failCoownershipFolderDocuments({ sliceId, params, error })),
  );
};
