import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ButtonReset, size, TitleAndAction } from 'ui';
import { PopoverContainer, PopoverInner, PopoverItem, PopoverItemButton, PopoverMenu } from 'ui/popover';
import unfold from 'images/icons/ic-unfold-more.svg';
import Dropdown from './Dropdown';
import { getSelectedAgency, getSelectedPlace } from 'selectors/agencies';
import { selectAgency as selectAgencyAction, selectPlace as selectPlaceAction } from 'actions/ui';
import { getPlace as getPlaceAction } from 'actions/places';
import { debounce, deburr, get, orderBy, pickBy } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import Search from 'components/Search';
import { MdChevronLeft } from 'react-icons/md';
import { useDebounce } from 'react-use';
import MDSpinner from 'react-md-spinner';
import { useAppState } from 'hooks';
import IcBuilding from './icons/Building';
import Avatar from './Avatar';
import Dotdotdot from 'react-dotdotdot';
import Select from 'components/Select';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { getOfferBadge } from 'containers/Places/PlacesList';
import { capitalize, SegmentedControls, Stack } from '@tymate/sergic';
import { history } from 'store';

const GET_PLACES = gql`
  query allPlaces(
    $search: String
    $cursor: String
    $inPortfolio: Boolean
    $sergicOffer: PlaceSergicOffers
    $agencyId: ID
  ) {
    allPlaces(
      search: $search
      first: 20
      after: $cursor
      inPortfolio: $inPortfolio
      filter: { sergicOffer: { eq: $sergicOffer }, agencyId: { eq: $agencyId } }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          sqlId
          displayName
          sergicOffer
          sergicOffer
        }
      }
    }
  }
`;

const MODE_PORTFOLIO = 'PORTFOLIO';
const MODE_AGENCIES = 'AGENCIES';
const MODE_AGENCY = 'AGENCY';

const Wrapper = styled.div`
  width: 100%;
`;

const BackButton = styled(ButtonReset)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing()};
  display: flex;
  align-items: center;

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.25)};
  }
`;

const PlaceTrigger = styled.div`
  color: #fff;
  display: flex;
  padding: ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  width: 100%;
`;

const PlaceTriggerBody = styled.span`
  flex: 1;
  vertical-align: middle;
  height: 35px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const PlaceTriggerLabel = styled.span`
  display: block;
  font-size: ${size(-1)};
  margin-top: 2px;
`;

export const PlaceTriggerName = styled(Dotdotdot)`
  display: block;
`;

const PlaceTriggerIconWrapper = styled.span`
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding-left: ${({ theme }) => theme.spacing(0.25)};
`;

const PopoverTitle = styled.p`
  margin: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()} 0;
  font-weight: 600;
  font-size: ${size(-1)};
`;

const PlaceTriggerIcon = styled.img`
  display: block;
`;

export const PlaceSwitcherTrigger = ({ selectedAgency, selectedPlace }) => {
  const { selectedAgencyId, selectedPlaceId } = useSelector(state => state.ui);

  return (
    <PlaceTrigger>
      <PlaceTriggerBody>
        <PlaceTriggerName clamp={1}>
          {selectedPlace?.displayName
            ? capitalize(selectedPlace?.displayName)
            : selectedPlaceId
            ? '…'
            : 'Tous les lieux'}
        </PlaceTriggerName>

        <PlaceTriggerLabel>
          {selectedAgency?.displayName
            ? capitalize(selectedAgency?.displayName)
            : selectedAgencyId
            ? '…'
            : 'Portefeuille'}
        </PlaceTriggerLabel>
      </PlaceTriggerBody>

      <PlaceTriggerIconWrapper>
        {Boolean(selectedPlace) ? (
          <Avatar
            size="small"
            variant="place"
            avatarUrl={selectedPlace.pictureUrl}
          />
        ) : (
          <IcBuilding size={32} />
        )}
      </PlaceTriggerIconWrapper>

      <PlaceTriggerIconWrapper>
        <PlaceTriggerIcon src={unfold} alt="" />
      </PlaceTriggerIconWrapper>
    </PlaceTrigger>
  );
};

export const AgencySwitcher = ({ onUpdate }) => {
  const dispatch = useDispatch();
  const { closeMobilePlace } = useAppState();
  const { agencyById, agenciesIds } = useSelector(state => state.agencies);
  const selectedAgencyId = useSelector(state => state.ui.selectedAgencyId);
  const selectedPlaceId = useSelector(state => state.ui.selectedPlaceId);
  const selectedAgency = useSelector(getSelectedAgency);
  const [mode, setMode] = useState(MODE_PORTFOLIO);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedOffer, setSelectedOffer] = useState('*');
  const placesScrollRef = useRef();

  const agencies = orderBy(
    agenciesIds.map(id => agencyById[id]).filter(Boolean),
    ({ displayName }) => displayName.toLowerCase(),
  ).filter(
    ({ displayName }) =>
      displayName
        .toLowerCase()
        .replace(/\s/g, '')
        .indexOf(deburr((search || '').toLowerCase().replace(/\s/g, ''))) > -1,
  );

  const { data: placesData, loading: isLoadingPlaces, fetchMore } = useQuery(
    GET_PLACES,
    {
      variables: pickBy({
        search: deburr(debouncedSearch),
        inPortfolio: mode === MODE_PORTFOLIO,
        sergicOffer: selectedOffer === '*' ? null : selectedOffer,
        agencyId: get(selectedAgency, 'uuid'),
      }),
    },
  );

  const places = get(placesData, 'allPlaces.edges', []);

  const handleLoadMore = debounce(() => {
    fetchMore({
      variables: {
        cursor: get(placesData, 'allPlaces.pageInfo.endCursor'),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        let newResult = {
          ...prev,
          allPlaces: {
            ...prev.allPlaces,
            pageInfo: get(fetchMoreResult, 'allPlaces.pageInfo', {}),
            edges: [
              ...prev.allPlaces.edges,
              ...get(fetchMoreResult, 'allPlaces.edges', []),
            ],
          },
        };

        return newResult;
      },
    });
  }, 200);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    300,
    [search],
  );

  const selectAgency = useCallback(
    (agencyId, agencyUuid) => {
      dispatch(selectAgencyAction(agencyId, agencyUuid));
    },
    [dispatch],
  );

  const selectPlace = useCallback(
    (placeId, placeUuid) => {
      dispatch(selectPlaceAction(placeId, placeUuid));
    },
    [dispatch],
  );

  const getPlace = useCallback(
    id => {
      dispatch(getPlaceAction(id));
    },
    [dispatch],
  );

  useEffect(() => {
    setSearch('');
  }, [selectedAgencyId]);

  const handleSelectMode = mode => {
    setMode(mode);
    selectPlace(null, null);

    if (mode === MODE_PORTFOLIO) {
      selectAgency(null, null);
    }
  };

  const handleSelectAgency = (agencyId, agencyUuid) => {
    selectAgency(agencyId, agencyUuid);
    selectPlace(null, null);
    setMode(MODE_AGENCY);
  };

  const handleSelectModeSegment = targetMode => {
    if (targetMode === MODE_PORTFOLIO) {
      handleSelectMode(MODE_PORTFOLIO);
    }

    if (targetMode === MODE_AGENCY && mode === MODE_PORTFOLIO) {
      handleSelectMode(MODE_AGENCIES);
    }
  };

  useEffect(
    () => {
      setMode(Boolean(selectedAgencyId) ? MODE_AGENCY : MODE_PORTFOLIO);
    },
    // eslint-disable-next-line
    [selectedAgency],
  );

  useEffect(() => {
    if (Boolean(selectedPlaceId)) {
      getPlace(selectedPlaceId);
    }
  }, [selectedPlaceId, getPlace]);

  return (
    <>
      <Stack padding={1}>
        <SegmentedControls
          size="full"
          activeValue={mode}
          onSelect={handleSelectModeSegment}
          values={[
            { value: MODE_PORTFOLIO, label: 'Portefeuille' },
            {
              value: MODE_AGENCY,
              label: 'Agence',
              alternateActiveValues: [MODE_AGENCIES],
            },
          ]}
        />
      </Stack>

      <Stack
        paddingHorizontal={1}
        direction="row"
        gutterSize={1}
        marginBottom={1}
      >
        <div style={{ flex: 2 }}>
          <Search
            size="full"
            value={search}
            onChange={e => setSearch(e.target.value)}
            onClear={() => setSearch('')}
          />
        </div>

        {mode !== MODE_AGENCIES && (
          <div style={{ textAlign: 'left', flex: 1 }}>
            <Select
              right
              shouldHideSearchInput
              popoverSize="small"
              options={[
                { value: '*', label: 'Toutes offres' },
                { value: 'ESE', label: 'éseis' },
                { value: 'VSY', label: 'Viva Syndic' },
                { value: 'ECO', label: 'e-Copro' },
                { value: 'NEUF', label: 'Neuf' },
              ]}
              value={selectedOffer}
              onChange={({ value }) => setSelectedOffer(value)}
            />
          </div>
        )}
      </Stack>

      {mode === MODE_PORTFOLIO && (
        <PopoverInner>
          Affiche les signalements, messages et tickets qui me sont destinés
        </PopoverInner>
      )}

      {mode === MODE_AGENCIES && (
        <Scrollbars autoHeight autoHeightMax={250}>
          <PopoverMenu>
            {agencies.map(({ id, uuid, displayName }) => (
              <PopoverItem key={id} isActive={get(selectedAgency, 'id') === id}>
                <PopoverItemButton
                  onClick={() => handleSelectAgency(id, uuid)}
                  isActive={get(selectedAgency, 'id') === id}
                >
                  {capitalize(displayName)}
                </PopoverItemButton>
              </PopoverItem>
            ))}
          </PopoverMenu>
        </Scrollbars>
      )}

      {mode === MODE_AGENCY && (
        <>
          <PopoverTitle>
            {capitalize(get(selectedAgency, 'displayName'))}
          </PopoverTitle>

          <BackButton
            type="button"
            onClick={() => {
              setMode(MODE_AGENCIES);
            }}
          >
            <MdChevronLeft /> Retour
          </BackButton>
        </>
      )}

      {mode !== MODE_AGENCIES && (
        <>
          {isLoadingPlaces ? (
            <PopoverInner>
              <MDSpinner
                style={{ marginRight: 8 }}
                singleColor="currentColor"
                size={16}
              />
            </PopoverInner>
          ) : (
            <Scrollbars
              autoHeight
              autoHeightMax={250}
              ref={placesScrollRef}
              onScroll={() => {
                const scrollRef = placesScrollRef.current;
                if (
                  !scrollRef ||
                  isLoadingPlaces ||
                  !scrollRef.getValues() ||
                  scrollRef.getValues().top < 0.9 ||
                  !get(placesData, 'allPlaces.pageInfo.hasNextPage')
                ) {
                  return;
                }

                handleLoadMore();
              }}
            >
              <PopoverMenu>
                <PopoverItem isActive={!selectedPlaceId}>
                  <PopoverItemButton
                    onClick={() => {
                      closeMobilePlace();
                      selectPlace();
                      if (Boolean(onUpdate)) {
                        onUpdate();
                      }
                      history.push('/');
                    }}
                    isActive={!selectedPlaceId}
                  >
                    Tous les lieux
                  </PopoverItemButton>
                </PopoverItem>

                {places
                  .map(({ node }) => ({ ...node }))
                  .map(({ id, sqlId, displayName, sergicOffer }) => (
                    <PopoverItem key={id} isActive={selectedPlaceId === id}>
                      <PopoverItemButton
                        onClick={() => {
                          closeMobilePlace();
                          selectPlace(sqlId, id);
                          if (Boolean(onUpdate)) {
                            onUpdate();
                          }
                          history.push('/');
                        }}
                        isActive={selectedPlaceId === sqlId}
                      >
                        <TitleAndAction hasNoBottomMargin>
                          <span>{capitalize(displayName)}</span>
                          <span>{getOfferBadge(sergicOffer)}</span>
                        </TitleAndAction>
                      </PopoverItemButton>
                    </PopoverItem>
                  ))}
              </PopoverMenu>
            </Scrollbars>
          )}
        </>
      )}
    </>
  );
};

const AgencySwitcherWrapper = () => {
  const selectedAgency = useSelector(getSelectedAgency);
  const selectedPlace = useSelector(getSelectedPlace);
  const dropdownRef = useRef();

  return (
    <Wrapper>
      <Dropdown
        ref={dropdownRef}
        shouldStayOpenWhenChangingUrl
        trigger={
          <PlaceSwitcherTrigger
            selectedAgency={selectedAgency}
            selectedPlace={selectedPlace}
          />
        }
      >
        <PopoverContainer size="large">
          <AgencySwitcher
            onUpdate={() => {
              dropdownRef.current.close();
            }}
          />
        </PopoverContainer>
      </Dropdown>
    </Wrapper>
  );
};

export default AgencySwitcherWrapper;
